import React, { useState, useEffect } from "react";
import { SongModel, WordModel, Song, SongInsertModel, SongUpdateModel } from "./database";
import { IoIosAdd, IoLogoWordpress, IoMdTrash } from "react-icons/io";


interface PropShape {
    isOpen: boolean;
    song: SongModel;
    onClose(): void;
}

export default (props: PropShape) => {
    const [title, setTitle] = useState<string>("");
    const [words, setWords] = useState<WordModel[]>([]);


    useEffect(() => {
        setWords(props.song.words);
        setTitle(props.song.name);
    }, [props.isOpen])


    if (!props.isOpen)
        return null;

    const addWord = (): void => {
        let newId = 1;
        const lastItem = words.slice(-1)[0];
        if (typeof lastItem === "object")
            newId += lastItem.id + 1;

        const newWord: WordModel = {
            value: "",
            isSkipped: false,
            id: newId,
        }

        setWords([...words, newWord])
    }

    const changeWord = (value: string, wordId: number): void => {
        const wordIndex = words.findIndex(w => w.id === wordId);
        let allWords = [...words];
        allWords[wordIndex].value = value;

        setWords(allWords);
    }

    const changeSkipped = (wordId: number): void => {
        const wordIndex = words.findIndex(w => w.id === wordId);
        let allWords = [...words];
        allWords[wordIndex].isSkipped = !allWords[wordIndex].isSkipped;

        setWords(allWords);
    }

    const deleteWord = (wordId: number): void => {
        const wordIndex = words.findIndex(w => w.id === wordId);
        let allWords = [...words];
        allWords.splice(wordIndex, 1);

        setWords(allWords);
    }

    const saveSong = (): void => {
        const edited: SongUpdateModel = {
            name: title,
            words,
        }
        Song.getInstance().edit(edited, props.song.id);
        props.onClose();
    }

    return (
        <div className="modal beatforbeat">
            <div className="content">

                <input className="title" value={title} placeholder="title" onChange={(e: any) => {
                    setTitle(e.target.value);
                }} />

                <div className="brickWall blocks">
                    <div className="header item">
                        <p>Words in the song</p>
                        <button onClick={addWord}><IoIosAdd /></button>
                    </div>

                    <ul>

                        {words.map((word: WordModel, i: number) => (
                            <li key={i} className="item">
                                <div className="edit">
                                    <input placeholder="Song Word" value={word.value} onChange={(e: any) => {
                                        changeWord(e.target.value, word.id);
                                    }} />
                                    <div>

                                        <label className="container">Skip
                                            <input type="checkbox" checked={word.isSkipped} onChange={() => {
                                                changeSkipped(word.id);
                                            }} />
                                            <span className="checkmark"></span>
                                        </label>

                                        <button className="block btn" onClick={() => {
                                            deleteWord(word.id);
                                        }}><IoMdTrash /></button>
                                    </div>

                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="btnDiv">
                    <button className="bfbBtn btn" onClick={props.onClose}>Cancel</button>
                    <button className="bfbBtn btn" onClick={saveSong}>Save</button>
                </div>
            </div>
        </div>
    )
}