
export interface GameInsertModel {
    name: string;
}

export interface GameModel extends GameInsertModel {
    id: number;
}

export interface WordModel {
    value: string;
    isSkipped: boolean;
    id: number;
}

export interface SongUpdateModel {
    name: string;
    words: WordModel[];
}

export interface SongInsertModel extends SongUpdateModel {
    gameId: number;
}

export interface SongModel extends SongInsertModel {
    id: number;
}

const GetNewId = (objs: any[]): number => {
    let newId = 1;
    const lastItem = objs.slice(-1)[0];
    if (typeof lastItem === "object")
        newId += lastItem.id + 1;

    return newId;
}


export class Song {
    private static instance: Song;

    private constructor() { }

    public static getInstance(): Song {
        if (!Song.instance)
            Song.instance = new Song();

        return Song.instance;
    }

    getAll(): SongModel[] {
        const localStorageObj = localStorage.getItem("songs");

        if (localStorageObj) {
            const allSongs = JSON.parse(localStorageObj);
            return allSongs;
        } else {
            return []
        }
    }

    getByGame(gameId: number): SongModel[] {
        const localStorageObj = localStorage.getItem("songs");

        if (localStorageObj) {
            const allSongs = JSON.parse(localStorageObj);
            const thisGameSongs = allSongs.filter((s: SongModel) => s.gameId === gameId);
            return thisGameSongs;
        } else {
            return []
        }
    }

    get(id: number): SongModel | null {
        const localStorageObj = localStorage.getItem("songs");

        if (!localStorageObj)
            return null;

        const allSongs = JSON.parse(localStorageObj);
        return allSongs.find((s: SongModel) => s.id === id);
    }

    create(song: SongInsertModel) {
        const insertSong: SongModel = {
            ...song,
            id: GetNewId(this.getAll()),
        }

        const allSongs = this.getAll();
        localStorage.setItem("songs", JSON.stringify([...allSongs, insertSong]));
    }

    edit(song: SongUpdateModel, songId: number) {
        const gameId = this.get(songId)?.gameId;

        const insertSong: SongModel = {
            ...song,
            gameId: gameId ? gameId : 1,
            id: GetNewId(this.getAll()),
        }

        let allSongs = this.getAll();
        allSongs.splice(allSongs.findIndex(s => s.id === songId), 1, insertSong);
        localStorage.setItem("songs", JSON.stringify(allSongs));
    }

    delete(songId: number) {
        let allSongs = this.getAll();
        allSongs.splice(allSongs.findIndex(s => s.id === songId), 1);
        localStorage.setItem("songs", JSON.stringify(allSongs));
    }
}


export class Game {
    private static instance: Game;

    private constructor() { }

    public static getInstance(): Game {
        if (!Game.instance)
            Game.instance = new Game();

        return Game.instance;
    }

    getAll(): GameModel[] {
        const localStorageObj = localStorage.getItem("games");

        if (localStorageObj) {
            const allGames = JSON.parse(localStorageObj);
            return allGames;
        } else {
            return []
        }
    }

    create(game: GameInsertModel) {
        const insertGame: GameModel = {
            ...game,
            id: GetNewId(this.getAll()),
        }

        const allGames = this.getAll();
        localStorage.setItem("games", JSON.stringify([...allGames, insertGame]));
    }

    edit(game: GameInsertModel, gameId: number) {
        const insertGame: GameModel = {
            ...game,
            id: GetNewId(this.getAll()),
        }

        let allGames = this.getAll();
        allGames.splice(allGames.findIndex(s => s.id === gameId), 1, insertGame);
        localStorage.setItem("games", JSON.stringify(allGames));
    }

    delete(gameId: number) {
        let allGames = this.getAll();
        allGames.splice(allGames.findIndex(s => s.id === gameId), 1);
        localStorage.setItem("games", JSON.stringify(allGames));

        let allSongs = Song.getInstance().getByGame(gameId);

        allSongs.forEach(song => {
            Song.getInstance().delete(song.id);
        })
    }
}