import React, { useState, useEffect } from "react";
import { IoIosAddCircleOutline, IoMdArrowBack } from "react-icons/io";
import { SongModel, Song, SongInsertModel, GameModel, WordModel } from "./database";

interface PropShape {
    closeMe(): void;
    game: GameModel;
}

interface ShowCard {
    isOpen: boolean;
    id: number;
}

export default (props: PropShape) => {
    const [cards, setCards] = useState<WordModel[]>([]);
    const [counter, setCounter] = useState<number>(0);
    const [showCards, setShowCards] = useState<ShowCard[]>([]);

    useEffect(() => {
        updateCards(0);
    }, [])

    const updateCards = (count: number): void => {
        setShowCards([]);
        setCounter(count);

        const songs = Song.getInstance().getByGame(props.game.id);

        if (songs.length > 0 && songs[count]) {
            setCards(songs[count].words);
        }
    }

    return (
        <div className="beatforbeat bfbPlayer">
            <div />

            <div className="b_row">
                {cards.map((card, i) => {
                    let showValue = false;
                    if (showCards.filter((c: ShowCard) => c.id === card.id).length > 0)
                        showValue = true;

                    let size = 400;
                    if (cards.length > 3) {
                        size = (window.innerWidth - 77) / cards.length;
                    }

                    return (
                        <div style={{ width: size, height: size, }} className={`card ${showValue ? "flip" : ""} ${card.isSkipped && showValue ? "redBorder" : ""}`} key={i} onClick={(e: any) => {
                            const newObj: ShowCard = {
                                isOpen: true,
                                id: card.id,
                            }

                            setShowCards([...showCards, newObj])
                        }}>
                                <div style={{ display: showValue ? "none": "block" }}>{i + 1}</div>
                                <div className={`flip`} style={{ display: showValue ? "block": "none" }}>{card.value}</div>
                        </div>
                    )
                })}
            </div>

            <div className="btnDiv">
                <button className="primary btn" onClick={() => {
                    props.closeMe();
                }}>Exit game</button>

                <button className="primary btn" disabled={Song.getInstance().getByGame(props.game.id).length - 1 === counter ? true : false} onClick={() => {
                    updateCards(counter + 1);
                }}>Next song</button>
            </div>
        </div>
    )
}