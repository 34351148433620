import React, { useState, useEffect } from "react"
import { IoIosAddCircleOutline, IoIosPlay, IoMdCreate, IoMdTrash } from "react-icons/io";
import GameEdit from "../../components/beatforbeat/GameEdit";
import { GameModel, GameInsertModel, Game, Song } from "../../components/beatforbeat/database";
import GamePlayer from "../../components/beatforbeat/GamePlayer";
import "../../components/beatforbeat/bfb.css";
import BGSection from "../../components/beatforbeat/BGSection";


const BeatForBeat = () => {
    const [games, setGames] = useState<GameModel[]>([]);
    const [currentGame, setCurrentGame] = useState<GameModel | null>(null);
    const [gamePlay, setGamePlay] = useState<GameModel | null>(null);

    useEffect(() => {
        updateData();
    }, [])

    const updateData = () => {
        setGames(Game.getInstance().getAll());
    }

    const addGame = () => {
        let newId = 1;
        const lastItem = games.slice(-1)[0];
        if (typeof lastItem === "object")
            newId += lastItem.id + 1;

        const name = prompt("Name of game");

        if (name) {
            const newGame: GameInsertModel = {
                name,
            };

            Game.getInstance().create(newGame);
            updateData();
        }
    };

    const playGame = (currGame: GameModel) => {
        const songsInGame = Song.getInstance().getByGame(currGame.id);
        let words = 0;
        
        songsInGame.forEach(song => {
            words += song.words.length;
        })
        if (songsInGame.length === 0 || words === 0) {
            alert("You need to create songs with words before you can play");
        } else {
            setGamePlay(currGame);
        }
    }

    const editGame = (currGame: GameModel) => {
        setCurrentGame(currGame);
    }

    const deleteGame = (currGame: GameModel) => {
        Game.getInstance().delete(currGame.id);
        updateData();
    }

    if (currentGame) {
        return <BGSection>
            <GameEdit game={currentGame} closeMe={() => {
                setCurrentGame(null);
            }} />
        </BGSection>
    } else if (gamePlay) {
        return <BGSection>
            <GamePlayer game={gamePlay} closeMe={() => {
                setGamePlay(null);
            }} />
        </BGSection>
    } else {
        return (
            <BGSection>
                <div className="beatforbeat">
                    <div className="bfbMsg b_center">
                        <p className="message">
                            Welcome to this "beat for beat" game. This is a simple way to play a version of  <a href="https://tv.nrk.no/serie/beat-for-beat">NRK's</a> popular game online. You create words in songs, and one (of the two) teams pick a number. They gonna sing a song with the word in it somewhere. All the words in a song create a phrase somewhere in that song. The team that picks a number continues to pick another number (and so on) when they are finished. If the number has a red border when it's picked, the turn goes to the other team.
                        </p>
                    </div>
                    <div className="blocks">
                        <div className="header item">
                            <p>All games</p>
                            <button onClick={addGame}><IoIosAddCircleOutline /></button>
                        </div>

                        <ul>
                            {games.map((game, i) => (
                                <li key={i} className="item">
                                    <p>{game.name}</p>
                                    <div>
                                        <button className="block btn" onClick={() => {
                                            playGame(game);
                                        }}><IoIosPlay /></button>
                                        <button className="block btn" onClick={() => {
                                            editGame(game);
                                        }}><IoMdCreate /></button>
                                        <button className="block btn" onClick={() => {
                                            deleteGame(game);
                                        }}><IoMdTrash /></button>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    </div>

                </div>
            </BGSection>
        )
    }
}

export default BeatForBeat

