import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

interface PropShape {
    children: React.ReactNode;
}

export default (props: PropShape) => (
    <StaticQuery
        query={graphql`
      query {
        desktop: file(relativePath: { eq: "bgbeatforbeat.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid
            return (
                <BackgroundImage
                    Tag="section"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                    fluid={imageData}
                    backgroundColor={`#040e18`}
                >
                    {props.children}
                </BackgroundImage>
            )
        }}
    />
)
