import React, { useState, useEffect } from "react";
import { IoIosAddCircleOutline, IoMdArrowBack, IoMdCreate, IoMdTrash } from "react-icons/io";
import { SongModel, Song, SongInsertModel, GameModel } from "./database";
import SongEditModal from "./SongEditModal";



interface PropShape {
    closeMe(): void;
    game: GameModel;
}

const BeatForBeat = (props: PropShape) => {
    const [songs, setSongs] = useState<SongModel[]>([]);
    const [song, setSong] = useState<SongModel | null>(null);

    useEffect(() => {
        updateData();
    }, [])

    const updateData = (): void => {
        setSongs(Song.getInstance().getByGame(props.game.id));
    }

    const addSong = () => {
        const name = prompt("Name of song");

        if (name) {
            const newSong: SongInsertModel = {
                name,
                words: [],
                gameId: props.game.id,
            }

            Song.getInstance().create(newSong)
            updateData();
        }
    };

    const editSong = (theSong: SongModel) => {
        setSong(theSong);
    }

    const deleteSong = (theSong: SongModel) => {
        Song.getInstance().delete(theSong.id);
        updateData();
    }

    const goBackToGames = (): void => {
        props.closeMe();
    }

    return (
        <div className="beatforbeat gameEdit">
            {song ? <SongEditModal onClose={() => { setSong(null) }} isOpen={true} song={song} /> : null}
            <div className="topHeader">
                <button onClick={goBackToGames}><IoMdArrowBack /></button>
                <p>{props.game.name}</p>
            </div>
            <div className="blocks">
                <div className="header item">
                    <p>All songs</p>
                    <button onClick={addSong}><IoIosAddCircleOutline /></button>
                </div>

                <ul>
                    {songs.map((song, i) => (
                        <li key={i} className="item">
                            <p>{song.name}</p>
                            <div>
                                <button className="block btn" onClick={() => {
                                    editSong(song);
                                }}><IoMdCreate /></button>
                                <button className="block btn" onClick={() => {
                                    deleteSong(song);
                                }}><IoMdTrash /></button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    )
}



export default BeatForBeat

